import * as React from "react"

const Time = ({ time, setTime }) => {
  return (
    <div className={"time"}>
      <div
        className={`time__type ${time === "day" ? "time__type--active" : ""}`}
      >
        <div className="time__label">W dzień</div>
        <div className="time__details">Od 6:00 do 22:00</div>
      </div>
      <div
        className={`time__switch ${
          time === "day" ? "" : "time__switch--active"
        }`}
        onClick={() => {
          const t = time === "day" ? "night" : "day"
          setTime(t)
        }}
      >
        <div className="time__switch__dot">
          {time === "day" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10 1.3913V2.17391M10 17.8261V18.6087M4.16626 16.0874L4.69659 15.534M15.3032 4.46623L15.8335 3.91284M4.16626 3.91261L4.69659 4.466M15.3032 15.5338L15.8335 16.0871M1.75 9.99999H2.5M17.5 9.99999H18.25M14.5 9.99999C14.5 12.5933 12.4853 14.6956 10 14.6956C7.51472 14.6956 5.5 12.5933 5.5 9.99999C5.5 7.40666 7.51472 5.30434 10 5.30434C12.4853 5.30434 14.5 7.40666 14.5 9.99999Z"
                stroke="white"
                strokeWidth="1.56522"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 13 14"
              fill="none"
            >
              <path
                d="M9.3656 9.8094C6.44754 9.8094 4.08199 7.40015 4.08199 4.4282C4.08199 3.55453 4.28641 2.7295 4.64921 2C2.5311 2.70103 1 4.72781 1 7.1188C1 10.0908 3.36555 12.5 6.28361 12.5C8.34385 12.5 10.1287 11.299 11 9.547C10.4853 9.71734 9.93603 9.8094 9.3656 9.8094Z"
                stroke="white"
                strokeWidth="1.33333"
              />
            </svg>
          )}
        </div>
      </div>
      <div
        className={`time__type ${time === "night" ? "time__type--active" : ""}`}
      >
        <div className="time__label">W nocy</div>
        <div className="time__details">Od 22:00 do 6:00</div>
      </div>
    </div>
  )
}

export default Time

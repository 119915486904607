import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Mobile = () => {
  return (
    <div className={"mobile"}>
      <div data-g="grid">
        <div data-g="4 12@sm 6@md">
          <h2>
            Zamawiaj także
            <br /> przez naszą apkę!
          </h2>
          <p>
            Pobierz darmową aplikację Mobi Taxi i uzyskaj dodatkowe możliwości
            takie jak monitorowanie aktualnej pozycji kierowcy, historia
            zamówień czy opcja oceny przejazdu!
          </p>
          <a
            className={"button button--secondary"}
            href={"http://mobitaxi.szczecin.pl/"}
          >
            Dowiedz się więcej
          </a>
        </div>
        <div data-g="4 12@sm offset-7@md 5@md">
          <StaticImage src="../../images/mobile.png" alt="mobile app" />
        </div>
      </div>
    </div>
  )
}

export default Mobile

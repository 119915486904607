import * as React from "react"
import Phone from "../../svg/phone"
import { useEffect, useState } from "react"
import { Loader } from "google-maps"
import { StaticImage } from "gatsby-plugin-image"
import Time from "../time"
import { getHour, mobileCheck } from "../../../lib/helpers"

const markers = { startPoint: null, endPoint: null }
const locations = { startPoint: null, endPoint: null }
let serviceType = "service-4"
let distance = 0
const prices = {
  start: 9.99,
  day: 3,
  night: 4.5,
  "service-4": function (distance, value) {
    return distance * value
  },
  "service-6": function (distance, value) {
    return 1.5 * (distance * value)
  },
  "service-kombi": function (distance, value) {
    return distance * value + 10
  },
  "service-towing": function (distance, value) {
    return distance * value * 4
  },
  "service-delivering": function (distance, value) {
    return distance * value * 3
  },
  "service-starting": function (distance, value) {
    return 9.99
  },
}

const debounce = (func, wait) => {
  let timeout

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

const Estimate = () => {
  const [price, setPrice] = useState(false)
  const [google, setGoogle] = useState(null)
  const [geocoder, setGeocoder] = useState(null)
  const [distanceMatrix, setDistanceMatrix] = useState(null)
  const [bounds, setBounds] = useState(null)
  const [basell, setBasell] = useState(null)
  const [map, setMap] = useState(null)
  const [showMap, setShowMap] = useState(false)
  const [time, setTime] = useState(null)
  const [isMobile, setMobile] = useState(true)
  const [options, showOptions] = useState(false)

  useEffect(() => {
    if (distanceMatrix) {
      processDistance()
    }
  }, [time])

  useEffect(() => {
    async function fetch() {
      const loader = new Loader("AIzaSyDFp7hKdAjAuLSbdm8yjR9wlT3ifiWvp6U", {})
      const g = await loader.load()
      console.log(g)

      setGoogle(g)

      setGeocoder(new g.maps.Geocoder())
      setDistanceMatrix(new g.maps.DistanceMatrixService())
      setBounds(
        new g.maps.LatLngBounds(
          new g.maps.LatLng(53.35, 14.45),
          new g.maps.LatLng(53.5, 14.79)
        )
      )
      setBasell(new g.maps.LatLng(53.42553, 14.556327))

      setMap(
        new g.maps.Map(document.querySelector(".map"), {
          zoom: 12,
          center: basell,
          mapTypeId: g.maps.MapTypeId.ROADMAP,
        })
      )

      console.log(map)
    }

    if (!geocoder) {
      fetch()
    }
  }, [google, geocoder, distanceMatrix, bounds, basell])

  useEffect(() => {
    setMobile(mobileCheck())

    const hour = getHour()
    if (hour > 6 && hour < 22) {
      setTime("day")
    } else {
      setTime("night")
    }
  }, [])

  const getAddress = function (input, callback) {
    var address = input.value,
      id = input.getAttribute("id")
    console.log(bounds)
    geocoder.geocode({ address, bounds }, function (results, status) {
      input.classList.remove("active")

      if (status === google.maps.GeocoderStatus.OK) {
        if (markers[id]) {
          markers[id].setMap(null)
        }

        setShowMap(true)
        locations[id] = results[0].geometry.location

        map.setCenter(locations[id])
        markers[id] = new google.maps.Marker({
          map: map,
          position: locations[id],
        })
        callback()
        input.classList.add("active")
      }
    })
  }

  const processDistance = function () {
    distanceMatrix.getDistanceMatrix(
      {
        origins: [locations["startPoint"]],
        destinations: [locations["endPoint"]],
        travelMode: google.maps.TravelMode.DRIVING,
        avoidHighways: false,
        avoidTolls: true,
      },
      function (response, status) {
        var results

        if (status === google.maps.DistanceMatrixStatus.OK) {
          results = response.rows[0].elements[0]
          distance = Math.round(results.distance.value / 1000)
          console.log(time)
          setPrice(
            Math.round(
              (prices["service-starting"]() +
                prices[serviceType](distance, prices[time])) *
                100
            ) /
              100 +
              " zł"
          )
        }
      }
    )
  }

  function resolvePosition({ target }) {
    getAddress(target, function () {
      processDistance()
    })
  }

  return (
    <form className={"estimate"}>
      <div className="estimate__background">
        <StaticImage
          loading={"lazy"}
          src="../../images/estimate/background.png"
          alt="background"
          objectFit={"cover"}
          formats={["auto", "webp", "avif"]}
          quality={90}
          outputPixelDensities={2}
        />
      </div>
      <div data-g="container">
        <div data-g={"grid flex-vertical-center"}>
          <div data-g="4 12@sm 6@md " className={"estimate__calculate"}>
            <h2>Oblicz orientacyjną cenę przejazdu</h2>
            <div className="form-group">
              <input
                placeholder="Skąd ruszamy?"
                name={"startPoint"}
                id={"startPoint"}
                onInput={debounce(resolvePosition, 500)}
              />
            </div>

            <div className="form-group">
              <input
                placeholder="Dokąd jedziemy?"
                name={"endPoint"}
                id={"endPoint"}
                onInput={debounce(resolvePosition, 500)}
              />
            </div>

            <div
              className="estimate__options"
              onClick={() => showOptions(!options)}
            >
              <img src="/images/estimate/settings.svg" alt="estimate" />
              Dodatkowe opcje
            </div>

            {options && (
              <>
                <div className="form-group">
                  <select
                    defaultValue={"service-4"}
                    onChange={event => {
                      serviceType = event.target.value
                      processDistance()
                    }}
                  >
                    <option value="service-4">
                      Transport osobowy do 4 pasażerów
                    </option>
                    <option value="service-6">
                      Transport osobowy do 6 pasażerów
                    </option>
                    <option value="service-kombi">
                      Kombi (do 4 pasażerów)
                    </option>
                    <option value="service-delivering">
                      Odprowadzanie pojazdu
                    </option>
                    <option value="service-towing">Holowanie</option>
                  </select>
                </div>
                <Time setTime={setTime} time={time} />
              </>
            )}

            {price && (
              <div className="estimate__final">
                <div className="estimate__result">
                  <h4>Szacowany koszt:</h4>
                  <div className="estimate__price">
                    <span className="resultPrice">{price}</span>
                  </div>
                </div>
                <div className="estimate__compute">
                  {isMobile ? (
                    <a
                      href={"tel:914-615-615"}
                      className={"button button--blue"}
                    >
                      <Phone />
                      zamawiam!
                    </a>
                  ) : (
                    <>
                      <span>Zamów przejazd!</span>
                      <button
                        type={"button"}
                        className={"button button--blue button--copy"}
                      >
                        <Phone />
                        914 615 615
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="estimate__disclaimer">
              Kalkulator nie bierze pod uwagę utrudnień aktualnie panujących na
              zadanej trasie. Finalny koszt może się nieznacznie różnić.
            </div>
          </div>
          <div data-g="4 12@sm 6@md">
            <div className="estimate__map">
              <div
                style={{
                  width: "100%",
                  height: isMobile ? (showMap ? "400px" : "0") : `530px`,
                }}
                className={showMap ? "map map--show" : "map"}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Estimate
